import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { setRoute } from "@/__main__/router.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_DESTINY_HEARTBEAT } from "@/game-destiny2/constants/events.mjs";
import { GAME_SYMBOL_DESTINY2 } from "@/game-destiny2/definition-symbol.mjs";
import { populateMatchHistory } from "@/game-destiny2/fetches/bungie-activities.mjs";
import { fetchProfileFromSteamId } from "@/game-destiny2/fetches/fetch-profile.mjs";
import { upsertBungieProfile } from "@/game-destiny2/fetches/post-upsert-user-profile.mjs";
import { addProfile } from "@/game-destiny2/utils/actions.mjs";
import { devDebug } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import { steam3to64 } from "@/util/steam.mjs";

const registerEventListeners = async () => {
  await initEvents;

  handleMessage(
    EVENTS.DESTINY_HEARTBEAT,
    (payload: { sessionId: string; steamId?: number; type: string }) => {
      devDebug("[Destiny] Received DEADLOCK_HEARTBEAT", payload);
      const steam3 = payload.steamId;
      eventBus.emit(EVENT_DESTINY_HEARTBEAT, {
        sessionId: payload.sessionId,
        type: payload.type,
        ...(steam3 && {
          steam3,
          steam64: steam3to64(steam3.toString()),
        }),
      });
    },
  );

  handleMessage(
    EVENTS.DESTINY_IS_RUNNING,
    async (payload: false | { running: boolean; steamId: number }) => {
      devDebug("[Destiny] Received DESTINY_IS_RUNNING", payload);
      if (!payload || !payload.running || !payload.steamId) {
        return;
      }
      // Constants
      const steam3 = payload.steamId;
      const steam64 = steam3to64(steam3.toString());
      // App
      updateLatestPlayed(GAME_SYMBOL_DESTINY2);
      const userProfile = await fetchProfileFromSteamId(steam64, {
        skipSafetyCheck: true,
      });
      if (!userProfile) return;
      populateMatchHistory(userProfile.bungieId); // Silently populate the user's match history
      if (!userProfile.blitzUser) await upsertBungieProfile(userProfile);
      addProfile(userProfile, steam3);
      setRoute(`/destiny2/profile/${userProfile.bungieId}`);
    },
  );
};

export default registerEventListeners;
